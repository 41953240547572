import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Design from "../../Assets/Design.gif";
import Launch from "../../Assets/Launch.gif";
import Market from "../../Assets/Market.gif";
import Meet from "../../Assets/Meet.gif";

import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { FaUsers,FaBriefcase, FaCheckCircle,FaGrinStars  } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
           <h1 style={{ fontSize: "2.6em" }}>
             <span className="purple"> Simple Process </span> 
            </h1>
        </Row>
       <Row>

    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div className="home-about-body">
              <div className="home-about-body-spanpart">
              <Row>
                <Col md={9}>
                <h2><span className="purple">Pre-Design</span></h2>
                  <p>Understanding of business goals to design and develop best solution</p>
                </Col>
                <Col md={3}>
                <img src={Meet} alt="Animated GIF" />
               </Col>
               </Row>
             </div>
       </div>
    </div>

    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
         <div className="home-about-body">
              <div className="home-about-body-spanpart">
              <Row>
              <Col md={3}>
                <img src={Design} alt="Animated GIF" />
               </Col>
                <Col md={9}>
                <h2><span className="purple">Design</span></h2>
                  <p>Designing of tailor-made application to carter customer’s needs</p>
                </Col>
                
               </Row>
             </div>
       </div>
    </div>
   

     <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div className="home-about-body">
              <div className="home-about-body-spanpart">
              <Row>
                <Col md={9}>
                <h2><span className="purple">Support</span></h2>
                  <p>Strategies to increase visits and thus enhance business as well</p>
                </Col>
                <Col md={3}>
                <img src={Market} alt="Animated GIF" />
               </Col>
               </Row>
             </div>
       </div>
    </div>

    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
         <div className="home-about-body">
              <div className="home-about-body-spanpart">
              <Row>
              <Col md={3}>
                <img src={Launch} alt="Animated GIF" />
               </Col>
                <Col md={9}>
                <h2><span className="purple">Development</span></h2>
                  <p>Building of application with latest technologies that makes it user-friendly</p>
                </Col>
                
               </Row>
             </div>
       </div>
    </div>
    
   

  </Row>
  <br/><br/>
  <Row >
    
    <h1 style={{ fontSize: "2.6em" }}>
             <span className="purple"> What We Aim? </span> 
            </h1>
            <br/>
            <h3 className="textofcontent">At ClicksWebz, we pride ourselves on being a trusted partner for businesses of all sizes,
             from SMEs to government institutions and enterprises. Our dedicated team of professionals has been instrumental
              in helping organizations achieve their digital transformation goals. By employing cutting-edge technologies, 
              staying ahead of emerging trends, and leveraging our experience, we consistently deliver tailored solutions that 
              meet the unique needs of our clients.</h3>
            <Row>
            <div class="parent-wrapper">
              <div className="workaim-box">
              <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front ">
                    <p>Happy Clients</p>
                    
                </div>
                <div className="flip-card-back">
                    <div className="whataim-icon">
                    <FaUsers />
                    </div>
                  <h2>320+</h2>
                </div>
              </div>
             </div>

             <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front ">
                    <p>Years Of Experience</p>
                    
                </div>
                <div className="flip-card-back">
                <div className="whataim-icon">
                  <FaBriefcase />
                  </div>
                  <h2>4+</h2>
                </div>
              </div>
             </div>

             <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front ">
                    <p>Project Delivered</p>
                    
                </div>
                <div className="flip-card-back">
                <div className="whataim-icon">
                    <FaCheckCircle />
                    </div>
                  <h2>120+</h2>
                </div>
              </div>
             </div>

             <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front ">
                    <p>Client Satisfication Rate</p>
                    
                </div>
                <div className="flip-card-back">
                <div className="whataim-icon">
                  <FaGrinStars/>
                  </div>
                  <h3>93+</h3>
                </div>
              </div>
             </div>

               
               

              </div>
              </div>
            </Row>


              
              
              
              
  </Row>
       
       <Row> 
      <div className="blankbox">
      </div>
      </Row>
                   
       
      </Container>
    </Container>
  );
}
export default Home2;
