import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard2() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify",fontSize: "15px" }}>
          
          Web development along with <span className="purple">web design </span>and <span className="purple">mobile application </span>development has been our forte,
           since the time we started providing our clients with sophisticated solutions. 
           The outstanding qualities that help us to provide top-notch services include:
            
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> On-time delivery
            </li>
            <li className="about-activity">
              <ImPointRight /> Tailor-made solutions
            </li>
            <li className="about-activity">
              <ImPointRight /> Latest transparent updates
            </li>
            <li className="about-activity">
              <ImPointRight /> Customer-friendly services
            </li>
            <li className="about-activity">
              <ImPointRight /> State of art technology included applications
            </li>
            <li className="about-activity">
              <ImPointRight /> Proven success records
            </li>
            <li className="about-activity">
              <ImPointRight /> Redefining standards
            </li>
          </ul>

          
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard2;
