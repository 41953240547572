import React, { useState } from 'react';
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import axios from 'axios';
import image1 from "./image1.jpg";


const SendMail = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

const handleChange = (e) => {
    console.log(e.target);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("in handleSubmit for sending mail")
    try {
      console.log("try block",formData)
      await axios.post('https://clickswebz.com/api/send', formData);
      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email.');
    }
  };

  return (
    <div>
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <Container fluid className="mail-section">
          <div className="fieldpart">
          <Row>
            <Col md={6}>
            <label>Your Name :</label>
            <input type="text" name="name" value={formData.name} onChange={handleChange} required />
            </Col>
            <Col md={6}>
            <label>Your Email :</label>
            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
            <label>Phone No :</label>
            <input type="text" name="phoneno" value={formData.phoneno} onChange={handleChange} required />
            </Col>
            <Col md={6}>
            <label>Country :</label>
            <input type="text" name="country" value={formData.country} onChange={handleChange} required />
            </Col>
            </Row>
            <label>Message :</label>
            <textarea name="message" value={formData.message} onChange={handleChange} required />
            <Row className="btnsubmit">
            <Button type="submit" >
              Submit
            </Button>
            </Row>
          </div>
        </Container>
      </form>
    </div>
    </div>

  );
};

export default SendMail;
