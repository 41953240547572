import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import laptopImg from "../../Assets/about.png";
import { BsArrowRight } from "react-icons/bs";
// import { LiaDigitalTachographSolid } from "react-icons/lia";


function Services() {
  return (
     <Container fluid className="project-section">
      <Particle />
      <Container>
        
        <Row>
            <div className="servicelist">
              <h2><strong className="purple">Digital Markting</strong></h2>
           
        
        <Row style={{justifyContent:"center",paddingBottom:"10px",paddingTop:"10px"}}>
          <div className="serviceBox">
            <Row>
            <Col md={10} >
              <h3>SEO Services</h3>
              <p>Search Engine Optimisation (or SEO) stands as the difference between being seen and being ignored. 
              Grab your target audience's eyeballs with the best-rated SEO agency in Melbourne, experience tangible growth in business,
               and be head-and-shoulder over your competition!</p> 
              </Col>
            <Col md={2}>
              <div className="arrowicon">
              <BsArrowRight />

              </div>
            </Col>
            </Row>

          </div>

           <div className="serviceBox">
            <Row >
            <Col md={10} >
              <h3 >Google Ads Services</h3>
              <p>Willing to slay the digital game but are shy of a spendthrift? AusBorn is here for your growing business.
               As a Google Ads agency in Melbourne, we help with capturing the attention of those you want. 
               We devise foolproof strategies and run data-driven campaigns that get you customers, not just clicks. So, are you ready to
                plan your next high-profit campaign? * appearing above the organic results is no short of Rank 1!</p> 
              </Col>
            <Col md={2}>
              <div className="arrowicon">
              <BsArrowRight />

              </div>
            </Col>
            </Row>

          </div>

          <div className="serviceBox">
            <Row >
            <Col md={10} >
              <h3 >Social Media Marketing</h3>
              <p>Let's be honest about it – gone are the days when you could upload a pic of a Golden Egg with random hashtags and get 60,
               087, 376 likes (and counting)! Now, it's time to leverage data-driven insights with the human intervention of proven capacity
                to make your followers tap-tap your content like there's no tomorrow. Let's slay the social scene together!</p> 
              </Col>
            <Col md={2}>
              <div className="arrowicon">
              <BsArrowRight />

              </div>
            </Col>
            </Row>

          </div>

          <div className="serviceBox">
            <Row >
            <Col md={10} >
              <h3 >content Marketing</h3>
              <p>Why settle for noise when you can orchestrate a symphony? At AusBorn, we can help your brand with the creation of
               broadcast-worthy content and market it after to elevate your brand's voice. Let our dedicated digital content marketing specialists from Melbourne sprinkle the perfect blend of wit, information, and sass to turn heads and win hearts, and who knows, your readers can become marketers for your content</p> 
              </Col>
            <Col md={2}>
              <div className="arrowicon">
              <BsArrowRight />

              </div>
            </Col>
            </Row>

          </div>
        </Row>
         </div>
</Row>

        <Row>
            <div className="servicelist">
           
              <h2><strong className="purple">Development</strong></h2>


           
        
        <Row style={{justifyContent:"center",paddingBottom:"10px",paddingTop:"10px"}}>
          <div className="serviceBox">
            <Row>
            <Col md={10} >
              <h3 >Web Development</h3>
              <p>Your search for the perfect site developer Clickswebz team ends here! Partner with the best Clickswebz website developers and experience the magic of our custom website development services. Our web development Melbourne-wide wizardry ensures an impressive online presence, helping 97% of clients achieve remarkable visibility. Embrace excellence with the best web development company, and let's make your digital aspirations a reality!</p> 
              </Col>
            <Col md={2}>
              <div className="arrowicon">
              <BsArrowRight />

              </div>
            </Col>
            </Row>

          </div>

           <div className="serviceBox">
            <Row >
            <Col md={10} >
              <h3 >Shopify Development</h3>
              <p>Need an online store for your conventional business or want to upgrade your existing online store with the latest features? AusBorn's Shopify web design service is the answer, my friend! With cutting-edge features, tailored designs, and seamless integrations, we empower you to captivate customers, boost conversions, and dominate the eCommerce landscape. Get ready to elevate your eCommerce game with our expert Shopify development. The future awaits!</p> 
              </Col>
            <Col md={2}>
              <div className="arrowicon">
              <BsArrowRight />

              </div>
            </Col>
            </Row>

          </div>

          <div className="serviceBox">
            <Row >
            <Col md={10} >
              <h3 >Wordpress Development</h3>
              <p>Ready to make your website dreams a reality? Step into our world of WordPress development wizardry, where 97% of clients experience a surge in online visibility. With our impeccable WordPress web designs and rapid WordPress website development skills, we will unleash the full potential of your site, leaving your competitors in awe. Choose us as your trusted WordPress web development company in Melbourne, and let's embark on a journey of leveling up your digital presence. Join us because it’s time to level up your digital game!</p> 
              </Col>
            <Col md={2}>
              <div className="arrowicon">
              <BsArrowRight />

              </div>
            </Col>
            </Row>

          </div>

          
        </Row>
         </div>
</Row>

<Row>
            <div className="servicelist">
              
           <h2><strong className="purple">Design</strong></h2>
        
        <Row style={{justifyContent:"center",paddingBottom:"10px",paddingTop:"10px"}}>
          <div className="serviceBox">
            <Row>
            <Col md={10} >
              <h3 >UI/UX Design</h3>
              <p>Discover the power of pixels at our UI UX design agency and unlock a world of creativity and innovation. With our unique blend of storytelling and cutting-edge UI UX design Melbourne-wide, we'll craft captivating user experiences that leave your audience spellbound. Let's embark on a journey of pixel perfection together.</p> 
              </Col>
            <Col md={2}>
              <div className="arrowicon">
              <BsArrowRight />

              </div>
            </Col>
            </Row>

          </div>

           <div className="serviceBox">
            <Row >
            <Col md={10} >
              <h3 >Graphic Design</h3>
              <p>Elevate your brand with captivating visuals and stunning designs from our top-notch graphic design agency in Melbourne. We specialise in creating visually striking solutions that leave a lasting impression. Our talented graphic designer Melbourne-wide will bring your ideas to life, ensuring that every pixel is perfectly placed. Elevate your brand with our exceptional graphic design services today.</p> 
              </Col>
            <Col md={2}>
              <div className="arrowicon">
              <BsArrowRight />

              </div>
            </Col>
            </Row>

          </div>

          <div className="serviceBox">
            <Row >
            <Col md={10} >
              <h3 >Branding</h3>
              <p>Prepare for an enchanting journey of brand transformation! Our branding services ClicksWebz are an exquisite blend of creativity and strategy, guaranteed to captivate your audience. Unlock the secrets of success with our logo design in Melbourne. Let's cast a spell on your business and make your competitors disappear!</p> 
              </Col>
            <Col md={2}>
             <div className="arrowicon">
              <BsArrowRight />

              </div>
            </Col>
            </Row>

          </div>

          
        </Row>
         </div>
</Row>
        
      </Container>
      <h1><br/><br/></h1>
    </Container>
  );
}

export default Services;
