import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import AboutCard2 from "./AboutCard2";
import laptopImg from "../../Assets/about.png";
import Toolstack from "./Toolstack";


function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "20px",
              paddingBottom: "50px",
            }}
          >   
            <h1 style={{ fontSize: "2.1em", paddingBottom: "10px" }}>
              Increase Your Online
              Visibility with<strong className="purple">&nbsp;ClicksWebz</strong>
            </h1>
            <Aboutcard />
            
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
        </Row>
        <Row>
        <Col
            md={12}
            style={{
              justifyContent: "center",
              paddingTop: "20px",
              paddingBottom: "50px",
            }}
          >   
           <h1 style={{ fontSize: "2.1em", paddingBottom: "10px" }}>
            Qualities That Help Us Standout
            </h1>
            <AboutCard2/>
          </Col>
          <h1 style={{ fontSize: "2.1em", paddingBottom: "10px" }}>Vision</h1>
          <p>Our vision is to turn into your most esteemed digital accomplice by uniting the right arrangement of abilities, experience, and character. We need to make the ideal arrangement of advanced answers for your novel venture.</p>
         
          <h1> <br/><br/></h1>
         
          <h1>.</h1>
           
         
        </Row>
        

      
      </Container>
    </Container>
  );
}

export default About;
