import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify",fontSize: "15px" }}>
          We at <span className="purple">ClicksWebz Pvt. Ltd.</span> provide our customers with unique and tailor-made 
          It consulting and enterprise solutions. The team of developers is highly talented and 
          knowledgeable to work across various platforms in diverse fields like healthcare, 
          oil and gas, transportation, retail, eLearning, hospitality etc.
            <br /><br/>
            Our team is always keen to adopt the latest technology into their working modules so that 
            our clients can discover new opportunities to excel so that they can stand out among their 
            competitors in the highly competitive world. The dedication of the team helps us to provide 
            one of its kind services that aid our clients to gain a competitive edge, with the help of 
            next-generation delivery models.
            <br /><br/>
            Inclusion of latest technologies like big data analytics, Internet of Things (IoT),
             artificial intelligence, enterprise mobility, cloud computing is their ball game, 
             so enhance client’s capabilities. These technologies help in easy digital transformation to 
             ease work and provide effective services for their clients.
            
          </p>
          
         
          
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
