import React from "react";
import "./Home_logo.css";
// import Typewriter from "typewriter-effect";

function Home_logo() {
  return (
    // <Typewriter
    //   options={{
    //     strings: [
    //       "ClicksWebz"
    //     ],
    //     autoStart: true,
    //     loop: true,
    //     deleteSpeed: 50,
    //   }}
    // />
   
    <h1 className="h1class">
      <span>Click</span><span>s</span><span>Webz</span>
    </h1>
  
  );
}

export default Home_logo;

