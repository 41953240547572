import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import designandremodeling from "../../Assets/Projects/designandremodeling.png";
import designbouleward from "../../Assets/Projects/designbouleward.png";
import dzinekitchen from "../../Assets/Projects/dzinekitchen.png";
import happydentistry from "../../Assets/Projects/happydentistry.png";
import kateevents from "../../Assets/Projects/kateevents.png";
import propertyX from "../../Assets/Projects/propertyX.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={designandremodeling}
              isBlog={false}
              title="Design On Remodeling"
              description="ON Design & Remodeling is a trusted home-builder proudly serving the Bay Area. As custom home builders, we have the absolute privilege of conceptualizing your ideas and turning them into your Dream Home reality."
              ghLink="https://onremodeling.com/" />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={designbouleward}
              isBlog={false}
              title="DB Events"
              description="Welcome to DB Events, where dreams become unforgettable experiences. We are a team of passionate professionals dedicated to bringing your vision to life. With our expertise in event planning and design, we create extraordinary moments that leave a lasting impression on our clients and their guests."
              ghLink="https://www.dbevents.ae/"
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dzinekitchen}
              isBlog={false}
              title="Dzine Kitchens"
              description="Dzine Kitchens is South West Sydney’s leading kitchen and bathroom renovation specialist. We have been in business for over 20 years, completing hundreds of renovations that exceed all expectations. Our team is fully insured and certified to offer you the best services at affordable prices. So, you can rest assured that you can get the best value for your money."
              ghLink="https://www.dzinekitchens.com.au/?utm_source=Gmb&utm_medium=Gmb&utm_campaign=Gmb"
                           
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={happydentistry}
              isBlog={false}
              title="Happy Dentistry"
              description="Our agenda is straightforward. We aim to give our patients the best quality dental care on each visit.We understand that our patient’s comfort and contentment are crucial to everything we do. That’s why our practice strives to create a serene, dependable, and upbeat atmosphere."
              ghLink="https://happy-dentistry.com.au/"
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={kateevents}
              isBlog={false}
              title="kateedmonds events"
              description="Kate Edmonds Events  takes your thoughts and inspirations as a starting point and then builds on and enhances them into timeless occasions beyond your wildest dreams that will have your guests talking about your celebration for years to come!"
              ghLink="https://www.kateedmondsevents.com/aboutus"
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={propertyX}
              isBlog={false}
              title="Property X"
              description="With over a decade of experience in Melbourne’s CBD and inner suburbs, Property X is committed to providing an experience like no other. We strive to educate – providing clear, expert advice, whilst tailoring our approach to best suit our clients."
              ghLink="https://propertyx.com.au/"
             
            />
          </Col>
        </Row>
        <br/><br/>

      </Container>
      <h1>.<br/></h1>
    </Container>
  );
}

export default Projects;
